import React from 'react';

import { Button, InternalLink } from '../input';
import { Card, List, ListItem, Split } from '../layout';
import { H1, Paragraph } from '../text';

const Home = () => (
  <Card style={{ maxWidth: '50rem' }}>
    <H1>
      Europawahl 2019<br/>
      alternativer Wahl&#8209;O&#8209;Mat
    </H1>
    <Paragraph>
      Du kennst das: Du machst den Wahl&#8209;O&#8209;Maten, klickst dich durch alle Fragen durch und dann kannst du maximal
      acht Parteien miteinander vergleichen. Buuuh! Das hat laut den <a rel="noreferrer"
      href="https://www.wahl-o-mat.de/europawahl2019/popup_faq.php">offiziellen FAQ</a> zwar einen <del>guten</del> Grund
      (<a rel="noreferrer" href="https://www.sueddeutsche.de/politik/europawahl-wahl-o-mat-muss-geaendert-werden-1.4455505"
        >unstrittig ist dieser keinesfalls</a>), macht aber keinen Spaß, wenn man sich umfassender informieren oder etwas
      Quatsch machen will.<br/>
      Darum habe ich die Daten des Wahl-O-Maten befreit und in ein neues Interface geklatscht, welches
      diese Einschränkung nicht besitzt. Zudem kann man auch gleich alle Begründungen der Parteien sehen, während man seine
      Antworten gibt und hat eine Live-Vorschau seines aktuellen Ergebnisses.
    </Paragraph>
    <Paragraph>
      Bitte beachte folgende Punkte, bevor Du fortfährst:
    </Paragraph>
    <List>
      <ListItem>Diese Anwendung wurde noch <strong>nicht gründlich getestet</strong>! Fehler sind wahrscheinlich!
        </ListItem>
      <ListItem>Schau vielleicht beim <a href="https://www.wahl-o-mat.de/europawahl2019/" rel="noreferrer">originalen
        Wahl&#8209;O&#8209;Mat für die Europawahl 2019</a> vorbei.</ListItem>
      <ListItem>Dies ist keine Wahlempfehlung, sondern lediglich eine Informationshilfe.</ListItem>
      <ListItem>Alle Daten sind 1:1 vom Wahl&#8209;O&#8209;Mat <strong>geklaut</strong>. Bitte verpfeif mich nicht, falls das
        irgendwie verboten sein sollte. <span role="img" aria-label="Schulterzucken">🤷️</span></ListItem>
    </List>
    <Split>
      <Button to="/questions/1">Jaja, gib’s mir einfach!</Button>
      <InternalLink to="/imprint">Impressum</InternalLink>
    </Split>
  </Card>
);

export default Home;