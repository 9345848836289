import React from 'react';
import styled, { keyframes } from 'styled-components';

const animationRotation = keyframes`
  from {
    transform: rotate(0deg) scale(1.5);
  }
  to {
    transform: rotate(360deg) scale(1.5);
  }
`;

const EUSVG = styled.svg`
  color: ${props => props.theme.color.europeYellow};
  width: 100%;
  height: 6rem;
  
  polygon {
    fill: currentColor;
  }
  
  @media (min-width: 50rem) {
    animation: ${animationRotation} 600s linear infinite;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
  }
`;

export const EUFlag = () => (
  <EUSVG version="1.1" width="810" height="540" viewBox="0 0 810 540">
    <desc>European flag</desc>
    <polygon points="225,281.5 242.6,294.3 235.9,273.5 253.5,260.7 231.7,260.7 225,240 218.3,260.7 196.5,260.7
	214.1,273.5 207.4,294.3 "/>
    <polygon points="315,84.1 308.3,104.8 286.5,104.8 304.1,117.7 297.4,138.4 315,125.6 332.6,138.4 325.9,117.7
	343.5,104.8 321.7,104.8 "/>
    <polygon points="249.1,150 242.4,170.7 220.6,170.7 238.2,183.5 231.5,204.3 249.1,191.5 266.7,204.3 260,183.5
	277.6,170.7 255.9,170.7 "/>
    <polygon points="255.9,350.7 249.1,330 242.4,350.7 220.6,350.7 238.2,363.5 231.5,384.3 249.1,371.5 266.7,384.3
	260,363.5 277.6,350.7 "/>
    <polygon points="321.7,416.6 315,395.9 308.3,416.6 286.5,416.6 304.1,429.4 297.4,450.2 315,437.3 332.6,450.2
	325.9,429.4 343.5,416.6 "/>
    <polygon points="613.5,260.7 591.7,260.7 585,240 578.3,260.7 556.5,260.7 574.1,273.5 567.4,294.3 585,281.5
	602.6,294.3 595.9,273.5 "/>
    <polygon points="495,84.1 488.3,104.8 466.5,104.8 484.1,117.7 477.4,138.4 495,125.6 512.6,138.4 505.9,117.7
	523.5,104.8 501.7,104.8 "/>
    <polygon points="550,183.5 543.3,204.3 560.9,191.5 578.5,204.3 571.8,183.5 589.4,170.7 567.6,170.7 560.9,150
	554.1,170.7 532.4,170.7 "/>
    <polygon points="567.6,350.7 560.9,330 554.1,350.7 532.4,350.7 550,363.5 543.3,384.3 560.9,371.5 578.5,384.3
	571.8,363.5 589.4,350.7 "/>
    <polygon points="501.7,416.6 495,395.9 488.3,416.6 466.5,416.6 484.1,429.4 477.4,450.2 495,437.3 512.6,450.2
	505.9,429.4 523.5,416.6 "/>
    <polygon points="411.7,440.7 405,420 398.3,440.7 376.5,440.7 394.1,453.5 387.4,474.3 405,461.5 422.6,474.3
	415.9,453.5 433.5,440.7 "/>
    <polygon points="405,60 398.3,80.7 376.5,80.7 394.1,93.5 387.4,114.3 405,101.5 422.6,114.3 415.9,93.5 433.5,80.7
	411.7,80.7 "/>
  </EUSVG>
);

export default EUFlag;